import getCookie from "@/frontend/utils/getCookie"

export default function componentInitializer(func, funcName = null) {
  let skip = false

  const consoleLog = (...messages) => {
    // Print logs only if a function name provided
    if (!funcName || !getCookie("debugLogsEnabled")) return

    const color = "\x1b[32m"
    const reset = "\x1b[0m"
    console.log(`${color}[Component ${funcName}]${reset}`, ...messages)
  }

  consoleLog("componentInitializer executed with a readyState: ", document.readyState)

  const listenTurboLoad = () => {
    // Handle the component when user submit back/forward buttons
    document.addEventListener("turbo:load", () => {
      if (skip) {
        consoleLog("Skip the turbo:load func")
        skip = false
      } else {
        consoleLog("Executed turbo:load with a readyState: ", document.readyState)
        func()
      }
    })
  }

  // Run the script in case events like "turbo:load" and "DOMContentLoaded" are finished before script initializing
  if (["complete", "interactive"].includes(document.readyState)) {
    if (document.readyState === "interactive") skip = true
    consoleLog("readyState: ", document.readyState)
    func()
    listenTurboLoad()
    return
  }

  if (document.readyState === "loading") {
    // Increase the speed of initializing the component (2x)
    // Handle the component with this listener when the page load first time
    document.addEventListener(
      "DOMContentLoaded",
      () => {
        consoleLog("DOMContentLoaded", document.readyState)

        func()
        skip = true
        listenTurboLoad()
      },
      { once: true },
    )
  }
}
