let cacheGetCookie = {}

export default function getCookie(name) {
  if (name in cacheGetCookie) return cacheGetCookie[name]

  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  const result = parts.length === 2 && parts.pop().split(";").shift()
  return (cacheGetCookie[name] = result)
}
